/*
 * Sign up
 */
.sign-up {
    width: 100%;
    max-width: 500px;

    .mx-auto {
        padding: 30px;
        border-radius: 5px;
        box-shadow: 0px 0px 15px rgb(0 0 0 / 10%);
    }

    a {
        color: #ed563b;
    }

    .authTitle {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        margin: 30px 0;
        text-align: center;
        @media (max-width: 425px) {
            margin: 0 0 15px 0;
        }

        span.or {
            margin: 0 10px;
        }
    }

    .socialButtons {
        span {
            color: #fff;

            &:hover {
                color: white;
                opacity: 1;
            }
        }

        .btn-facebook {
            color: #3b5998;
            -webkit-transition: all 0.5s ease-in-out;
            -moz-transition: all 0.5s ease-in-out;
            -o-transition: all 0.5s ease-in-out;
            transition: all 0.5s ease-in-out;

            &:focus,
            &:hover {
                color: #172d5e
            }
        }

        .btn-google {
            color: #c32f10;
            -webkit-transition: all 0.5s ease-in-out;
            -moz-transition: all 0.5s ease-in-out;
            -o-transition: all 0.5s ease-in-out;
            transition: all 0.5s ease-in-out;

            &:focus,
            &:hover {
                color: #6b1301
            }
        }

        .btn-twitter {
            color: #00aced;
            -webkit-transition: all 0.5s ease-in-out;
            -moz-transition: all 0.5s ease-in-out;
            -o-transition: all 0.5s ease-in-out;
            transition: all 0.5s ease-in-out;

            &:focus,
            &:hover {
                color: #043d52
            }
        }
    }

    .sign-upOr {
        position: relative;
        font-size: 1.5em;
        color: #ed563b;
        margin-bottom: 1em;
        padding-top: 0.5em;
        padding-bottom: 0.5em;

        .hrOr {
            background-color: #ed563b;
            height: 1px;
            margin-top: 0px !important;
            margin-bottom: 0px !important;
        }

        .spanOr {
            display: block;
            position: absolute;
            left: 50%;
            top: -.8em;
            margin-left: -6em;
            background-color: white;
            width: 12em;
            text-align: center;
        }
    }
}