/*
 * Program
 */
.program {
    .form-control {
        &:focus {
            box-shadow: none;
        }

        &:read-only {
            background-color: transparent;
            cursor: default;

            &:focus {
                border-color: transparent;
            }
        }
    }

    .program-day {
        .btn {
            em {
                font-style: normal;

                @media (max-width: 320px) {
                    display: none;
                }
            }
        }

        .program-exercise {
            @media (max-width: 425px) {
                .exercise-item .btn-group {
                    width: 100%;
                }
            }
        }
    }
}

/*
 * Program create
 */
 .program-create {
    .mx-auto {
        padding: 30px;
        border-radius: 5px;
        box-shadow: 0px 0px 15px rgb(0 0 0 / 10%);
    }    
}