/*
 * Landing page
 */
.landing {
    .section-heading {
        text-align: center;
        margin-top: 140px;
        margin-bottom: 80px;
    }

    .section-heading h2 {
        font-size: 28px;
        font-weight: 800;
        color: #232d39;
        text-transform: uppercase;
        letter-spacing: 0.5px;
        margin-top: 0px;
        margin-bottom: 0px;
    }

    .section-heading h2 em {
        font-style: normal;
        color: #ed563b;
    }

    .section-heading img {
        margin: 20px auto;
    }

    .main-banner {
        position: relative;

        #bg-video {
            min-width: 100%;
            min-height: 100vh;
            max-width: 100%;
            max-height: 100vh;
            object-fit: cover;
            z-index: -1;

            &::-webkit-media-controls {
                display: none !important;
            }

            &.background {
                background-image: url('../images/video-cover.jpg');
                background-size: cover;
                background-position: center center;
            }
        }

        .video-overlay {
            position: absolute;
            background-color: rgba(35, 45, 57, 0.8);
            top: 0;
            left: 0;
            bottom: 7px;
            width: 100%;

            .caption {
                text-align: center;
                position: absolute;
                width: 80%;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);

                h6 {
                    margin-top: 0px;
                    font-size: 18px;
                    text-transform: uppercase;
                    font-weight: 800;
                    color: #fff;
                    letter-spacing: 0.5px;
                }

                h2 {
                    margin-top: 30px;
                    margin-bottom: 25px;
                    font-size: 84px;
                    text-transform: uppercase;
                    font-weight: 800;
                    color: #fff;
                    letter-spacing: 1px;

                    @media screen and (max-width: 425px) {
                        font-size: 65px;
                    }

                    @media screen and (max-width: 375px) {
                        font-size: 55px;
                    }

                    @media screen and (max-width: 320px) {
                        font-size: 45px;
                    }

                    em {
                        font-style: normal;
                        color: #ed563b;
                        font-weight: 900;
                    }
                }
            }
        }
    }

    #features {
        margin-bottom: 80px;

        ul {
            li {
                .right-content {
                    h4 {
                        margin-top: 0px;
                        margin-bottom: 7px;
                        letter-spacing: 0.25px;
                        color: #232d39;
                        font-size: 19px;
                        font-weight: 600;
                    }

                    .text-button {
                        display: inline-block;
                        margin-top: 7px;
                        padding: 0;
                        font-size: 13px;
                        text-transform: uppercase;
                        color: #ed563b;
                        font-weight: 500;
                        background: transparent;
                        border: 0;

                        &:hover {
                            font-weight: 600;
                        }
                    }

                    .more {
                        height: 0;
                        overflow: hidden;
                        font-size: 14px;
                        line-height: 25px;
                        background: #eee;
                        border-radius: 4px;
                        opacity: 0;
                        transition: opacity 1s ease-out;

                        &.active {
                            height: auto;
                            margin-top: 7px;
                            margin-bottom: 7px;
                            padding: 7px 14px;
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }

    #pricing {
        margin-bottom: 140px;

        ul {
            margin: 0;
            padding: 0;

            li {
                margin: 0 15px 30px 15px;

                @media screen and (max-width: 425px) {
                    margin: 0 5px 15px 5px;
                }


                button {
                    display: inline-block;
                    width: 100%;
                    padding: 30px 15px;
                    color: #232d39;
                    font-size: 19px;
                    font-weight: 600;
                    text-align: left;
                    text-transform: capitalize;
                    letter-spacing: 0.5px;
                    border: 0;
                    border-radius: 5px;
                    background-color: #fff;
                    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
                    transition: all 0.3s;

                    @media screen and (max-width: 425px) {
                        padding: 15px 5px;
                        text-align: center;
                    }

                    @media screen and (max-width: 375px) {
                        font-size: 16px;
                    }

                    @media screen and (max-width: 320px) {
                        font-size: 15px;
                    }

                    &.active {
                        color: #ed563b;
                    }

                    img {
                        max-width: 100%;
                        margin-right: 20px;

                        @media screen and (max-width: 425px) {
                            display: none;
                        }
                    }
                }
            }
        }

        .tabs-content {
            padding: 0 15px;
            transition: all 0.3s;

            @media screen and (max-width: 425px) {
                margin: 0;
            }

            article {
                display: none;

                &.active {
                    display: block;
                }

                img {
                    border-radius: 5px;
                }

                h4 {
                    font-size: 23px;
                    font-weight: 700;
                    color: #232d39;
                    letter-spacing: 0.5px;
                    margin-bottom: 20px;
                    margin-top: 30px;
                }

                ul {
                    font-size: 14px;
                    color: #7a7a7a;

                    li {
                        margin: 0 0 10px 0 !important;
                    }
                }

                em {
                    color: #ed563b;
                }
            }
        }
    }

    #contact-us {
        margin-top: 140px;

        .container-fluid {
            .col-lg-6 {
                padding: 0px;
            }
        }

        .section-heading {
            margin: 30px 0 0 0;
        }

        .contacts {
            padding: 80px;
            text-align: center;

            @media screen and (max-width: 992px) {
                padding: 30px;
            }

            p {
                font-size: 16px;
                line-height: 32px;

                a {
                    color: #ed563b;

                    i {
                        font-size: 18px;
                    }
                }
            }
        }

        .contact-form {
            padding: 80px;
            background-image: url('../images/contact-bg.jpg');
            background-position: center center;
            background-repeat: no-repeat;
            background-size: cover;

            @media screen and (max-width: 992px) {
                padding: 30px;
            }


            #contact {
                background-color: #fff;
                padding: 40px;
                border-radius: 5px;

                @media screen and (max-width: 992px) {
                    #contact {
                        padding: 30px;
                    }
                }
            }

            input,
            textarea {
                width: 100%;
                height: 40px;
                margin-bottom: 30px;
                padding: 0px 10px;
                color: #7a7a7a;
                font-size: 13px;
                line-height: 17px;
                border: 1px solid #ddd;
                background-color: #fff;
                outline: none;
                -webkit-appearance: none;
                -moz-appearance: none;
                appearance: none;
            }

            textarea {
                height: 150px;
                padding: 5px 10px;
                resize: none;
                margin-bottom: 0;
            }

            ::-webkit-input-placeholder {
                /* Edge */
                color: #7a7a7a;
            }

            :-ms-input-placeholder {
                /* Internet Explorer 10-11 */
                color: #7a7a7a;
            }

            ::placeholder {
                color: #7a7a7a;
            }

            button {
                display: inline-block;
                font-size: 13px;
                padding: 11px 17px;
                background-color: #ed563b;
                color: #fff;
                text-align: center;
                font-weight: 400;
                text-transform: uppercase;
                transition: all .3s;
                border: none;
                outline: none;

                &:hover {
                    background-color: #f9735b;
                }
            }
        }
    }
}

@-webkit-keyframes slide-down {
    0% { opacity: 0; -webkit-transform: translateY(-100%); }   
  100% { opacity: 1; -webkit-transform: translateY(0); }
}

@-moz-keyframes slide-down {
    0% { opacity: 0; -moz-transform: translateY(-100%); }   
  100% { opacity: 1; -moz-transform: translateY(0); }
}

@-webkit-keyframes slide-up {
    0% { opacity: 1; -webkit-transform: translateY(0); }   
  100% { opacity: 0; -webkit-transform: translateY(-100%); }
}

@-moz-keyframes slide-up {
    0% { opacity: 1; -moz-transform: translateY(0); }   
  100% { opacity: 0; -moz-transform: translateY(-100%); }
}
