html,
body {
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    background-color: #fff;
    font-size: 16px;
    -ms-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-display: swap;
}


::selection {
    background: #ed563b;
    color: #fff;
}

::-moz-selection {
    background: #ed563b;
    color: #fff;
}

.app {
    width: 100%;
    height: 100%;
    padding-top: 80px;
    background: #fff;
}

.app a:hover {
    text-decoration: none;
}

.wrapper {
    min-height: calc(100vh - 190px);
    overflow-x: hidden;

    .content {
        transform: none !important;
    }
}

p {
    font-size: 14px;
    line-height: 25px;
    color: #7a7a7a;
}

.valid-feedback,
.invalid-feedback {
    display: block;
}

@media (max-width: 991px) {

    html,
    body {
        overflow-x: hidden;
    }
}

.main-button a {
    display: inline-block;
    font-size: 15px;
    padding: 12px 20px;
    background-color: #ed563b;
    color: #fff;
    text-align: center;
    font-weight: 400;
    text-transform: uppercase;
    transition: all .3s;

    &:hover {
        background-color: #f9735b;
    }
}

.btn {
    border: none;
    border-radius: 0;
    box-shadow: none !important;

    &:active,
    &:focus-visible,
    &:focus-within,
    &:focus {
        border: none;
        box-shadow: none !important;
    }
}

.btn-primary {
    padding: 12px 20px;
    font-size: 15px;
    background-color: #ed563b;
    border: none;
    border-radius: 0;
    color: #fff;
    transition: all .3s;

    &:hover,
    &:active,
    &:focus-visible,
    &:focus {
        background-color: #f9735b;
    }

    &:disabled {
        background-color: #7a7a7a;
        border-color: #7a7a7a;
    }
}

.btn-outline-primary {
    padding: 12px 20px;
    font-size: 15px;
    border: 1px solid #ed563b;
    border-radius: 0;
    color: #ed563b;
    background-color: transparent;
    transition: all .3s;

    &:hover,
    &:active,
    &:focus-visible,
    &:focus {
        color: #fff;
        border: 1px solid #ed563b;
        background-color: #ed563b;
    }
}

.btn-secondary {
    padding: 12px 20px;
    font-size: 15px;
    color: #212529;
    background-color: transparent;
    transition: all .3s;

    &:active,
    &:focus-visible,
    &:focus-within,
    &:focus {
        color: #ed563b;
        background-color: transparent;
    }

    &:hover {
        color: #f9735b;
        background-color: transparent;
    }
}

.btn-outline-secondary {
    padding: 12px 20px;
    font-size: 15px;
    color: #212529;
    background-color: #fff;
    transition: all .3s;

    &:active,
    &:focus-visible,
    &:focus-within,
    &:focus {
        color: #ed563b;
        background-color: #fff;
    }

    &:hover {
        color: #f9735b;
        background-color: #fff;
    }
}

.btn-outline-success {
    color: #212529;
    background: transparent;

    &:hover,
    &:active,
    &:focus-visible,
    &:focus {
        color: #198754;
        background: transparent;
    }
}

.btn-outline-danger {
    color: #212529;

    &:hover,
    &:active,
    &:focus-visible,
    &:focus {
        color: #dc3545;
        background: transparent;
    }
}

input[type="text"],
input[type="email"],
input[type="password"],
input[type="number"] {
    box-shadow: none;

    &:hover,
    &:focus,
    &:focus-visible,
    &:active {
        box-shadow: none;
        border-color: #ed563b;
    }
}

.form-control-plaintext {
    &:hover,
    &:focus,
    &:focus-visible,
    &:active {
        box-shadow: none !important;
        border-color: transparent !important;
        outline: none !important;
    }
}

.select {
    &__control {
        border-color: hsl(0, 0%, 70%) !important;
        box-shadow: none !important;

        &:hover {
            border-color: #ed563b !important;
            box-shadow: none !important;
        }
    }

    &__option {
        &--is-focused {
            color: #ed563b !important;
            background-color: transparent !important;
        }

        &--is-selected {
            color: #fff !important;
            background-color: #ed563b !important;
        }
    }
}

.card {
    background: #fff;
    border: none;
    border-radius: 5px;
    box-shadow: 0px 0px 15px rgb(0 0 0 / 10%);

    span {
        display: inline-block;
        margin-bottom: 10px;
        color: #ed563b;
        font-size: 13px;
        font-weight: 500;
    }

    h5 {
        margin: 0;
        color: #232d39;
        font-size: 19px;
        font-weight: 600;
        letter-spacing: 0.5px;
    }
}

.list-group {
    &:not(.list-group-flush) {
        .list-group-item {
            background: #fff;
            border: none;
            border-radius: 5px;
            box-shadow: 0px 0px 15px rgb(0 0 0 / 10%);

            h5 {
                margin: 0;
                color: #232d39;
                font-size: 19px;
                font-weight: 600;
                letter-spacing: 0.5px;
            }

            span {
                display: inline-block;
                padding: 0;
                margin: 0;
                color: #ed563b;
                font-size: 13px;
                font-weight: 500;
                background: transparent;
                border-radius: 0;
            }

            .performance-group {
                input {
                    color: #000;
                    background: #f8f9fa;
                    border: 0;
                    border-radius: 0;
                }

                button,
                button:active,
                button:focus {
                    border: none;
                    border-radius: 0;
                    outline: none;
                    box-shadow: none;
                }
            }
        }
    }

    &-flush {
        .list-group-item {
            border: 0;
            border-radius: 0;
            box-shadow: none;
        }
    }
}

.dropdown-menu {
    border: 0;
    border-radius: 0;
    box-shadow: 0px 0px 15px rgb(0 0 0 / 10%);
}

.pagination {
    .page-item {
        .page-link {
            color: #ed563b;
            border: 0;
            box-shadow: none;

            &:hover {
                font-weight: 500;
                background-color: #fff;
            }

            i {
                font-size: 80%;
            }
        }

        &.active {
            .page-link {
                color: #fff;
                background: #ed563b;
            }
        }
    }
}

.alert-primary {
    font-size: 15px;
    color: #fff;
    background-color: #ed563b;
    border-color: #ed563b;
    border-radius: 0;
}

.modal {
    &.show {
        .modal-dialog {
            margin-top: 100px;
        }
    }
}

.loading {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100vh;
    background-color: #fff;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    opacity: 1;
    visibility: visible;
    z-index: 1000;
    -webkit-transition: opacity 0.25s ease;
    transition: opacity 0.25s ease;

    &.loaded {
        opacity: 0;
        visibility: hidden;
        pointer-events: none;
    }

    .loading-inner {
        position: relative;
        width: 142px;
        height: 40px;

        .dot {
            position: absolute;
            width: 16px;
            height: 16px;
            top: 12px;
            left: 15px;
            background: #232d39;
            border-radius: 50%;
            -webkit-transform: translateX(0);
            transform: translateX(0);
            -webkit-animation: dot 2.8s infinite;
            animation: dot 2.8s infinite;
        }

        .dots {
            -webkit-transform: translateX(0);
            transform: translateX(0);
            margin-top: 12px;
            margin-left: 31px;
            -webkit-animation: dots 2.8s infinite;
            animation: dots 2.8s infinite;

            span {
                display: block;
                float: left;
                width: 16px;
                height: 16px;
                margin-left: 16px;
                background: #232d39;
                border-radius: 50%;
            }
        }
    }
}

@-webkit-keyframes dot {
    50% {
        -webkit-transform: translateX(96px);
        transform: translateX(96px);
    }
}

@keyframes dot {
    50% {
        -webkit-transform: translateX(96px);
        transform: translateX(96px);
    }
}

@-webkit-keyframes dots {
    50% {
        -webkit-transform: translateX(-31px);
        transform: translateX(-31px);
    }
}

@keyframes dots {
    50% {
        -webkit-transform: translateX(-31px);
        transform: translateX(-31px);
    }
}