/*
 * Header
 */
.headroom {
    top: 0;
    position: fixed;
    width: 100%;
    will-change: transform;
    transition: transform 200ms linear;
    z-index: 2000 !important;

    &--pinned {
        transform: translateY(0%);
    }

    &--unpinned {
        transform: translateY(-100%);
    }
}

.header-area {
    height: 80px;
    background: #fff;
    -webkit-transition: all .5s ease 0s;
    -moz-transition: all .5s ease 0s;
    -o-transition: all .5s ease 0s;
    transition: all .5s ease 0s;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
    z-index: 100;

    .main-nav {
        min-height: 80px;
        background: transparent;

        .menu-toggle {
            display: block;
            padding: 0 10px 0 0;
            margin: 0 10px 0 0;
            color: #000;
            font-size: 26px;
            border: 0;
            background: transparent;
        }

        .logo {
            line-height: 80px;
            color: #000;
            font-size: 32px;
            font-weight: 800;
            text-transform: uppercase;
            float: left;
            -webkit-transition: all 0.3s ease 0s;
            -moz-transition: all 0.3s ease 0s;
            -o-transition: all 0.3s ease 0s;
            transition: all 0.3s ease 0s;

            em {
                font-style: normal;
                color: #ed563b;
                font-weight: 900;
            }
        }

        .nav {
            position: relative;
            background-color: transparent;
            -webkit-transition: all 0.3s ease 0s;
            -moz-transition: all 0.3s ease 0s;
            -o-transition: all 0.3s ease 0s;
            transition: all 0.3s ease 0s;
            z-index: 999;

            li {
                padding-left: 15px;
                padding-right: 15px;

                a {
                    display: block;
                    font-weight: 500;
                    font-size: 13px;
                    color: #000;
                    text-transform: uppercase;
                    -webkit-transition: all 0.3s ease 0s;
                    -moz-transition: all 0.3s ease 0s;
                    -o-transition: all 0.3s ease 0s;
                    transition: all 0.3s ease 0s;
                    height: 40px;
                    line-height: 40px;
                    border: transparent;
                    letter-spacing: 1px;

                    &:hover,
                    &.active {
                        color: #ed563b !important;
                    }
                }

                &.main-button {
                    padding: 0;

                    a {
                        display: inline-block;
                        font-size: 13px;
                        padding: 10px 20px;
                        background-color: #ed563b;
                        color: #fff;
                        text-align: center;
                        font-weight: 400;
                        letter-spacing: 0px;
                        text-transform: uppercase;
                        transition: all .3s;
                        height: auto;
                        line-height: 20px;

                        &:hover {
                            color: #fff !important;
                            background-color: #f9735b;
                            opacity: 1;
                        }
                    }
                }

                &.profile {
                    position: relative;

                    button {
                        padding: 0;
                        border: 0;
                        background: transparent;

                        .default {
                            position: relative;
                            display: inline-block;
                            width: 30px;
                            height: 30px;
                            color: #fff;
                            font-size: 16px;
                            text-align: center;
                            line-height: 30px;
                            background: #232d39;
                            border-radius: 50%;
                        }
                    }

                    .popup {
                        position: absolute;
                        top: 60px;
                        padding: 20px 0;
                        color: #000;
                        background: rgba(255, 255, 255, 0.95);
                        border: none;
                        border-radius: 0;
                        box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.1);

                        button {
                            margin: 0 0 10px 0;
                            padding: 0 20px;
                            font-size: 13px;
                            font-weight: 500;
                            text-transform: uppercase;
                            letter-spacing: 1px;
                        }

                        button:last-child {
                            margin: 0;
                        }
                    }
                }
            }
        }

        .language-select {
            height: 40px;
            margin: 0 15px;
            color: #000;
            font-weight: 500;
            font-size: 13px;
            text-transform: uppercase;
            line-height: 40px;
            letter-spacing: 1px;
            background-color: transparent;
            border: none;
            outline: none;
            box-shadow: none;
            cursor: pointer;
            -webkit-transition: all 0.3s ease 0s;
            -moz-transition: all 0.3s ease 0s;
            -o-transition: all 0.3s ease 0s;
            transition: all 0.3s ease 0s;

            &__control {
                background-color: transparent;
                border: 0;
                border-radius: 0;
                box-shadow: none;
                cursor: pointer;
            }

            &__value-container {
                padding: 0;
                background-color: transparent;
            }

            &__indicator-separator {
                display: none;
            }

            &__indicator {
                padding: 0;
            }

            &__menu {
                top: 60px;
                right: 0;
                width: fit-content;
                margin: 0;
                background: rgba(255, 255, 255, 0.95);
                border: 0;
                border-radius: 0;
                box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.1);

                &-list {
                    padding: 0;
                    border: 0;
                    border-radius: 0;
                }
            }

            &__option {
                padding: 0 20px;
                cursor: pointer;

                &--is-focused {
                    color: #000;
                    background-color: transparent;
                }

                &--is-selected {
                    color: #ed563b;
                    background-color: transparent;
                }
            }
        }
    }
}