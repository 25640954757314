/*
 * Workout
 */
.workout {
    h4 {
        .badge {
            color: #fff;
            background-color: #ed563b;
            border-radius: 0;
        }

        small {
            color: #ed563b;
        }
    }
}

/*
 * Workout exercise item
 */
.exercise-item {
    .card-header {
        background: #fff;
        border: 0;
        border-radius: 5px;

        .toggle {
            .symbol {
                margin-bottom: 0;
                font-family: "Font Awesome 5 Pro";
                font-weight: 900;
                font-size: inherit;
                cursor: pointer;
            }

            &[aria-expanded="false"] {
                .symbol {
                    &::before {
                        content: "\f32d";
                    }
                }
            }

            &[aria-expanded="true"] {
                .symbol {
                    &::before {
                        content: "\f331";
                    }
                }
            }
        }

        span {
            margin: 0;

            em {
                font-style: normal;

                &.previous {
                    color: #a7a7a7;
                }
            }
        }
    }
}

.exercise-performance {
    &__menu {
        z-index: 1000 !important;
    }
}

/*
 * Workout start
 */
.workout-start {
    .mx-auto {
        padding: 30px;
        border-radius: 5px;
        box-shadow: 0px 0px 15px rgb(0 0 0 / 10%);
    }    
}

/*
 * Workout active
 */
.workout-active {
    .btn-end {
        margin: 31px auto;

        @media (max-width: 375px) {
            margin: 15px auto;
        }
    }
}