.subscribe {
    .card-img-top {
        height: auto;
    }

    .card-disabled {
        opacity: .7;
    }
}

.subscribe-modal {
    .list-group-item {
        border-radius: 0;
        box-shadow: none;

        .form-control-qty {
            display: inline-block;
            width: 50px;
            font-size: 0.875em;
        }

        .btn-link {
            padding-top: 0;
            padding-bottom: 0;
            vertical-align: baseline;
        }
    }
}