/*
 * Profile
 */
.profile {
    &-img {
        position: relative;
        overflow: hidden;

        img {
            position: relative;
            width: 200px;
            height: 200px;
            margin: 0 auto;
            background: #eee;
            z-index: 1;
        }

        .default {
            position: relative;
            width: 200px;
            height: 200px;
            color: #fff;
            font-size: 75px;
            text-align: center;
            line-height: 200px;
            background: #ccc;
        }

        &-actions {
            position: absolute;
            bottom: -40px;
            width: 100%;
            height: 40px;
            background-color: rgba(0, 0, 0, .5);
            z-index: 10;
            transition: all .25s;

            button {
                background: transparent;
                border: none;
                outline: none;
                appearance: none;

                &:hover {
                    outline: none;
                    appearance: none;
                }
            }

            input {
                display: none;
            }
        }

        &:hover {
            .profile-img-actions {
                bottom: 0;
            }
        }
    }

    .btn-outline-white,
    .btn-outline-white:hover,
    .btn-outline-white:focus {
        background-color: #fff !important;
        color: #28a745 !important;
    }
}

/*
 * Reset password
 */
.reset-password {
    width: 100%;
    max-width: 500px;

    .mx-auto {
        padding: 30px;
        border-radius: 5px;
        box-shadow: 0px 0px 15px rgb(0 0 0 / 10%);
    }

    h3 {
        margin: 30px auto;
    }
}