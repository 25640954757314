/*
 * Navigation
 */
.menu-head {
    padding: 100px 0 30px 0;
    text-align: center;
    background: #fff;
    cursor: pointer;

    &.signed-out {
        padding-top: 60px;
    }

    .profile-img {
        position: relative;
        overflow: hidden;
        margin: 0 0 10px 0;
        text-align: center;

        img {
            position: relative;
            width: 100px;
            height: 100px;
            margin: 0 auto;
            background: #fff;
            z-index: 1;
        }

        .default {
            position: relative;
            display: inline-block;
            width: 100px;
            height: 100px;
            color: #fff;
            font-size: 50px;
            text-align: center;
            line-height: 100px;
            background: #232d39;
            border-radius: 50%;
        }
    }

    h4 {
        margin: 20px 0 0 0;
        color: #232d39;
        font-size: 14px;
    }
}

.menu {
    height: 100%;
    padding: 0 0 25px 0;
    background: #fff;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
    z-index: 1000;

    ul {
        margin: 0 -25px;
        padding: 0 25px;
        list-style: none;

        li {
            padding: 10px 25px;
            transition: all .25s;

            a,
            button {
                padding: 0;
                color: #232d39;
                text-decoration: none;
                border: 0;
                background: transparent;
                outline: none;

                &:hover,
                &:active,
                &:focus {
                    color: #232d39;
                    text-decoration: none;
                }
            }

            &.active {
                background-color: #232d39;

                button {
                    color: #fff;
                }
            }

            &.alt {
                padding-top: 5px;
                padding-bottom: 5px;
                font-size: 85%;
            }

            &.seperator {
                padding-top: 0;
                padding-bottom: 0;
            }
        }
    }

    hr {
        margin: 10px 0;
        border-top: 1px solid #232d39;
    }
}