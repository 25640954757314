/*
 * Footer
 */
footer {
    background: #232d39;

    .container {
        padding-top: 30px;
        padding-bottom: 30px;
        text-align: center;

        p {
            color: #fff;
            font-size: 13px;

            a {
                cursor: pointer;
                color: #fff;

                &:hover {
                    color: #fff;
                }
            }
        }
    }
}